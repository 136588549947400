<template>
  <div v-if="state.access" class="auth-wrapper auth-v2">
    <b-row v-if="state.display" class="chargement">
      <div
        class="spinner-grow text-indigo"
        style="width: 7rem; height: 7rem"
        role="status"
      ></div>
    </b-row>

    <b-row v-else class="auth-inner m-0">
      <!-- Brand logo-->

      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <vuexy-logo />
        </h2>
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->

      <b-col lg="4" class="d-none d-lg-flex align-items-center">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>

      <!-- /Left Text-->

      <!-- Register-->

      <b-col lg="8" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center">
            {{ $t("message.login.eheader") }} !
          </b-card-title>

          <b-card-text class="mb-2 text-center">
            {{ $t("message.login.esubHeader") }} 🏫
          </b-card-text>

          <!-- form -->

          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <b-row>
                <b-col lg="12">
                  <!-- champs -->

                  <!-- ligne 1 -->

                  <b-row>
                    <b-col lg="12">
                      <!-- nom -->

                      <b-form-group class="text-danger" label-for="entreprise-name">
                        <label for=""
                          >{{ $t("message.login.input.NameOrganization") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <b-form-input
                          id="entreprise-nom"
                          v-model="newCompagny.name"
                          name="entreprise-nom"
                          placeholder="Qenium"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'nom'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- domaine d'activité -->
                  <b-row>
                    <b-col lg="6">
                      <b-form-group class="text-danger" label-for="entreprise-username">
                        <label for=""
                          >{{ $t("message.login.input.companyID") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <b-form-input
                          id="entreprise-username"
                          v-model="newCompagny.username"
                          name="entreprise-username"
                          placeholder="Qenium-company"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'username'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group label-for="entreprise-domaine">
                        <label for=""
                          >{{ $t("message.login.input.FieldOfActivity") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <v-select
                          v-model="newCompagny.domaine"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="libelle"
                          :options="fieldOfActivity"
                          :placeholder="$t('message.login.input.phFieldOfActivity')"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'domaine_id'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- /ligne 1 -->

                  <!-- ligne 2 -->

                  <b-row>
                    <!-- email -->

                    <b-col lg="6">
                      <b-form-group label-for="entreprise-email">
                        <label for=""
                          >{{ $t("message.login.input.email") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <b-form-input
                          id="entreprise-email"
                          v-model="newCompagny.email"
                          name="entreprise-email"
                          placeholder="info@mitama.com"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'email'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>

                    <!-- Taille -->

                    <b-col lg="6">
                      <b-form-group label-for="entreprise-taille">
                        <label for=""
                          >{{ $t("message.login.input.Size") }} ({{
                            $t("message.login.input.StaffMembers")
                          }}) <span class="p-0 text-danger h6">*</span></label
                        >

                        <v-select
                          v-model="newCompagny.size"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="libelle"
                          :options="companySize"
                          :placeholder="$t('message.login.input.phSize')"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'taille_id'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- /ligne 2 -->

                  <!-- ligne 3 -->

                  <b-row>
                    <!-- site web -->

                    <b-col lg="6">
                      <b-form-group
                        :label="$t('message.login.input.SiteWeb')"
                        label-for="entreprise-siteweb"
                      >
                        <b-form-input
                          id="entreprise-siteweb"
                          v-model="newCompagny.webSite"
                          name="entreprise-siteweb"
                          placeholder="www.mitama.com"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Devise -->

                    <b-col lg="6">
                      <b-form-group label-for="entreprise-devise">
                        <label for="">
                          {{ $t("message.login.input.Currency") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <v-select
                          v-model="newCompagny.currency"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="description"
                          :options="companyCurrency"
                          :placeholder="$t('message.login.input.phCurrency')"
                        >
                          <template #option="option">
                                  {{ option.description }}
                                </template>
                                </v-select>
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'devise_id'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- /ligne 3 -->

                  <!-- ligne 4 -->

                  <b-row>
                    <!-- contact -->

                    <b-col lg="6">
                      <!-- contact -->

                      <b-form-group label-for="register-contact" class="localisation">
                        <label for="">
                          {{ $t("message.login.input.contact") }}
                          <span class="p-0 text-danger h6">*</span></label
                        >
                        <vue-tel-input
                          id="register-contact"
                          @validate="indicatif($event)"
                          v-model="newCompagny.contact"
                          name="register-contact"
                          placeholder="000-000-000-000"
                        />
                        <span
                          class="text-danger"
                          style="font-size: 12px"
                          v-if="errorInput.path === 'contact'"
                        >
                          {{ errorInput.message }}
                        </span>
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <!-- Numéro fixe -->

                      <b-form-group
                        :label="$t('message.login.input.FixedPhone')"
                        label-for="entreprise-telfixe"
                      >
                        <b-form-input
                          type="number"
                          id="entreprise-telfixe"
                          v-model="newCompagny.contactFixe"
                          name="entreprise-telfixe"
                          placeholder="000-000-000-000"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- /ligne 4 -->

                  <!-- ligne 5 -->

                  <label for="">
                    {{ $t("message.login.input.location") }}
                    <span class="text-danger">*</span>
                  </label>
                  <div id="custom-search-input" class="mb-1">
                    <div class="input-group">
                      <input
                        id="autocomplete_search"
                        @input="initialize"
                        name="autocomplete_search"
                        type="text"
                        class="form-control"
                        :placeholder="$t('message.login.input.phLocation')"
                      />
                      <input type="hidden" name="lat" />
                      <input type="hidden" name="long" />
                    </div>
                    <span
                      class="text-danger"
                      style="font-size: 12px"
                      v-if="errorInput.path === 'localisation'"
                    >
                      {{ errorInput.message }}
                    </span>
                  </div>

                  <!-- /ligne 5 -->

                  <!-- ligne6 -->

                  <b-form-group
                    :label="$t('message.login.input.file') + ' (512X512)'"
                    label-for="entreprise-telfixe"
                  >
                    <b-form-file
                      style="display: none"
                      id="entreprise-logo"
                      class="text-center"
                      v-model="newCompagny.file"
                      @change="processFile($event)"
                      no-drop
                    />

                    <label for="entreprise-logo">
                      <div
                        style="
                          height: 100px !important;
                          width: 100px !important;
                          object-fit: cover !important;
                          border: 2px dashed rgba(0, 0, 0, 0.2);
                          border-radius: 7%;
                        "
                        class="w-100 p-50 rounded"
                      >
                        <img
                          src="https://via.placeholder.com/150/4B0082/FFFFFF/?text=Ediqia"
                          style="height: 100%; width: 100%; border-radius: 7%"
                          alt="entreprise logo"
                          class="SetImageCompagny object-cover"
                        />
                      </div>
                    </label>
                  </b-form-group>
                </b-col>

                <!-- /champs -->
              </b-row>

              <b-button
                disabled
                variant="primary"
                block
                type="submit"
                class="bg-indigo mt-2"
                v-if="state.loading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </b-button>

              <b-button
                variant="primary"
                block
                type="submit"
                class="bg-indigo mt-2"
                v-else
                @click.prevent="companyStore"
              >
                {{ $t("message.login.createYourOrganization") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span> {{ $t("message.login.is_login") }} </span>

            <b-link @click="clearToConnect" :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("message.login.btn_login") }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>

      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BootstrapDropdown } from "vue-custom-google-autocomplete";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { BFormFile } from "bootstrap-vue";
import URL from "@/views/pages/request";
import axios from "axios";
import { VueTelInput } from "vue-tel-input";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import slugify from "slugify";
import { toast_orign } from "@/utils/qToast";
import { qCompanyValidator } from "@/utils/validators/entrepriseValidate";
import { onBeforeMount, onMounted, reactive, watch, ref } from "@vue/composition-api";
import qFileInBlod from "@/utils/qFileInBlod";

export default {
  components: {
    VueGoogleAutocomplete,
    BootstrapDropdown,
    VuexyLogo,
    VueTelInput,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormFile,
    // validations
    ValidationProvider,
    ValidationObserver,
  },

  setup(props, { root }) {

    const sideImg = ref(require("@/assets/images/pages/register-v2.svg"))
    const newCompagny = reactive({
      name: "",
      username: "",
      domaine: "",
      email: "",
      size: "",
      webSite: "",
      currency: "",
      indicatif: "",
      contact: "",
      contactFixe: "",
      location: "",
      file: null,
    });

    const errorInput = reactive({
      error: false,
      path: "",
      message: "",
    });

    const state = reactive({
      display: false,
      access: true,
      loading: false,
    });

    const fieldOfActivity = ref([]);
    const companySize = ref([]);
    const companyCurrency = ref([]);

    // onBeforeMount(async () => {
    //   await axios.get(URL.VERIFICATION).then((response) => {
    //     const returnData = response.data;
    //     // if (returnData.entreprise_exist && !returnData.abonnement_exist) {
    //     //   root.$router.push({ name: "pack" });
    //     // } else if (returnData.entreprise_exist) {
    //     //   root.$router.push({ name: "home" });
    //     // } else {
    //     //   state.access = true;
    //     // }
    //     state.access = true;
    //     root.$router.push({ name: "home" });
    //   });
    // });

    onMounted(async () => {
      document.title = "Nouvelle entreprise - Ediqia";
      google.maps.event.addDomListener(window, "load", initialize);
      await bootstraping();
    });

    watch(
      () => _.cloneDeep(newCompagny.name),
      (currentValue, oldValue) => {
        const us = slugify(currentValue, {
          replacement: "-",
          remove: /[*+~.;{},#=%$`<\?\{\&\}>|\]\*\+\$\.\\\-\^['"!:@]/g,
          lower: true,
          strict: true,
          trim: true,
        });
        newCompagny.username = us;
      }
    );

    const initialize = () => {
      var input = document.getElementById("autocomplete_search");
      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        var place = autocomplete.getPlace();
        // place variable will have all the information you are looking for.
        $("#lat").val(place.geometry["location"].lat());
        $("#long").val(place.geometry["location"].lng());
        localStorage.setItem("placeCompagny", JSON.stringify(place));
      });
    };

    const indicatif = (e) => {
      newCompagny.indicatif = e.country.dialCode;
    };

    const bootstraping = async () => {
      const { data } = await axios.get(URL.PARAMETRE_ALL, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (data) {
        const params = data.parametre_liste;
        // console.log('dataParamettre', params)
        fieldOfActivity.value = params.filter(
          (type) => type.type_parametre_id === 2 && type.parent_id === null
        );
        companySize.value = params.filter((type) => type.type_parametre_id === 3);
        companyCurrency.value = params.filter((type) => type.type_parametre_id === 1);
        console.log(companyCurrency.value)
      }
    };

    const clearToConnect = () => {
      localStorage.clear();
    };

    const processFile = (event) => {
      newCompagny.file = event.target.files[0];
      qFileInBlod.showImage(newCompagny.file, "SetImageCompagny");
    };

    const companyStore = async () => {
      newCompagny.location = localStorage.getItem("placeCompagny");
      const newFormdata = new FormData();

      newFormdata.append("image", newCompagny.file);

      newFormdata.append("libelle", newCompagny.name);

      newFormdata.append("username", newCompagny.username);

      newFormdata.append("domaine_id", newCompagny.domaine.id);

      newFormdata.append("taille_id", newCompagny.size.id);

      newFormdata.append("email", newCompagny.email);

      newFormdata.append("contact", newCompagny.contact);

      newFormdata.append("site_internet", newCompagny.webSite);

      newFormdata.append("fixe", newCompagny.contactFixe);

      newFormdata.append("indicateur", newCompagny.indicatif);

      newFormdata.append("localisation", [newCompagny.location]);

      newFormdata.append("devise_id", newCompagny.currency.id);

      const inputData = {
        nom: newCompagny.name,
        username: newCompagny.username,
        domaine_id: newCompagny.domaine,
        email: newCompagny.email,
        taille_id: newCompagny.size,
        devise_id: newCompagny.currency,
        indicateur: newCompagny.indicatif,
        contact: newCompagny.contact,
        localisation: newCompagny.location,
      };

      const { error, path, message } = await qCompanyValidator(inputData);
      if (error) {
        errorInput.error = true;
        errorInput.path = path;
        errorInput.message = message;
        return;
      } else {
        state.loading = true;
        axios
          .post(URL.ENTREPRISE_STORE, newFormdata, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then(({ data }) => {
            if (data.error) {
              console.log(data.error);
              const errors = data.error;
              errorInput.error = errors.error;
              errorInput.path = errors.path;
              errorInput.message = errors.message;
              state.loading = false;
            } else if (data.entreprise) {
              localStorage.removeItem("qhze_001");

              toast_orign(
                root,
                "Bienvenue chez ediqia",
                "MailIcon",
                "Votre organisation a été créé avec succès",
                "success",
                "top-right"
              );

              state.loading = false;
              if (localStorage.getItem("qhze_001") === null) {
                root.$router.push("/");
              }
            }
          })
          .catch((error) => {
            state.loading = false;
            if (error.response) {
              if (error.response.status === 422) {
                const errors = error.response.data.errors;
                if (errors) {
                  Object.keys(errors).map((key) => {
                    errorInput.error = true;
                    errorInput.path = key;
                    errorInput.message = errors[key][0];
                  });
                }
              }
            }
          });
      }
    };

    return {
      sideImg,
      newCompagny,
      errorInput,
      state,
      fieldOfActivity,
      companySize,
      companyCurrency,
      initialize,
      clearToConnect,
      processFile,
      companyStore,
      indicatif,
    };
  },

  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties

        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");

        return this.sideImg;
      }

      return this.sideImg;
    },
  },
};

/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/pages/page-auth.scss";

.none {
  display: none;
}

.block {
  display: inline-block;
}
.vti__dropdown-list {
  z-index: 12000000000;
}
</style>
